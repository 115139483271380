import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import PurchaseRefinance from "../components/Repeating/PurchaseRefinance";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanPrograms";
import Resources from "../components/Repeating/Resources";
import About from "../components/Repeating/About";
import StatesWeServe from "../components/Repeating/StatesWeServe";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonIcon from "../components/Button/ButtonIcon";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Full-Service Mortgage Lender | Right Start Mortgage"
        description="At Right Start, we're a full-service mortgage lender who takes the time to get to know you and your goals and then helps you with every step. Get started today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-14 overflow-hidden pt-52 pb-22 md:mb-22 md:py-56">
        <div className="absolute left-0 top-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-homepage.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="absolute left-0 top-0 h-full w-full md:hidden">
          <StaticImage
            src="../images/1.0 Homepage/Hero mobile.jpg"
            loading="eager"
            className="h-full"
            imgClassName="object-top"
          />
        </div>
        <div className="container">
          <div className="relative mx-auto max-w-[520px] text-center md:mx-0 md:text-left">
            <div className="mb-4 hidden font-heading text-mobile-7xl font-extrabold text-white md:block md:text-7xl">
              Destination: home
            </div>
            <div className="mb-2.5 space-x-2.5 font-heading font-semibold uppercase tracking-wider text-primary-50/70 md:hidden">
              <i class="fa-solid fa-location-dot"></i>
              <span>Destination:</span>
            </div>
            <div className="mb-8 font-heading text-mobile-7xl font-bold text-white md:hidden">
              Home
            </div>
            <p className="mb-10 text-xl text-white md:mb-3 md:text-[1.375rem] md:leading-[2rem]">
              A personal home loan experience from dedicated mortgage experts.
              Trusted since 1989.
            </p>
            <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
              <ButtonSolid
                href="/home-purchase/"
                text="Purchase"
                altStyle={2}
                className="w-full md:w-auto"
              />
              <ButtonSolid
                href="/home-refinance/"
                text="Refinance"
                className="w-full md:w-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <StaticImage
                src="../images/1.0 Homepage/2.0 Intro.png"
                loading="lazy"
                width={560}
              />
            </div>
            <div className="order-1 md:order-2">
              <h1>A Full-Service Mortgage Lender With Individual Focus</h1>
              <p>
                We’re here especially for you—whether you're purchasing a home,
                refinancing your existing loan, or want some cash for home
                improvements. You get all the information you need from our
                knowledgeable mortgage advisors, an easy 5-step loan process
                from beginning to close, and personalized service.
              </p>
              <ButtonIcon href="/about/" text="Learn More" altStyle={2} />
            </div>
          </div>
        </div>
      </section>

      <ValueProps />
      <PurchaseRefinance />
      <Testimonials />
      <LoanPrograms />
      <Resources />
      <About />
      <StatesWeServe />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
